/* MUSTOM, More Than Custom, https://mustom.com */
/* Copyright © Ryu Woosik. All rights reserved. */


#CheckoutLeft {
    float: left;
    width: calc(100% - 400px);
    padding-right: 50px;
}

#CheckoutRight {
    float: right;
    width: 400px;
    height: 100%;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    background: #F1F1F1;
}

.CheckoutMenu {
    display: block;
    border-bottom: 1px solid #888888;
    margin-bottom: 40px;
    font-weight: 500;
}

.CheckoutMenuContent {
    padding: 0 20px;
}

#CheckoutSummary {
    position: relative;
    padding: 50px;
}

#CheckoutTotal {
    padding: 20px 0;
    border-top: 1px solid #888888;
    border-bottom: 1px solid #888888;
}

#CheckoutTotal::after {
    clear: both;
    content: "";
    display: table;
}

#CheckoutTotal .HalfContainer.HalfRight {
    text-align: right;
}

#CheckoutTotal .HalfContainer {
    padding: 5px 0;
}

.CheckoutItemBlock {
    max-height: 300px;
    margin: 30px 0;
}

.CheckoutItem {
    width: 100%;
    margin-bottom: 20px;
}

.CheckoutItem::after {
    content: "";
    display: table;
    clear: both;
}

.CheckoutItem .ItemImage {
    float: left;
    width: 50px;
    height: 50px;
}

.ItemImage img {
    width: 100%;
    height: 100%;
}

.CheckoutItem .ItemDetail {
    float: right;
    width: calc(100% - 50px);
    padding-left: 10px;
    overflow: hidden;
}

#CheckoutConsent {
    margin-top: 20px;
    font-size: 13px;
}

#CheckoutLeave {
    position: absolute;
    right: 50px;
    bottom: 20px;
    font-size: 12px;
    opacity: 0.8;
    cursor: pointer;
}

#CheckoutLeave:hover {
    opacity: 1;
}

#CheckoutEmail {
    margin-bottom: 20px;
}

#CheckoutEmail p {
    color: #666666;
    font-size: 12px;
}

#CheckoutEmail span:first-of-type {
    display: inline-block;
    margin-right: 10px;
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    vertical-align: middle;
}

#CheckoutEmail span:last-child {
    display: inline-block;
    color: #D00000;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}

#CheckoutEmail span:last-child:hover {
    opacity: 0.8;
}


#CheckoutAddressContainer {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
}

#CheckoutAddressContainer #AddAddressButton {
    font-size: 13px;
    border-bottom: 1px solid #333333;
    cursor: pointer;
}

#CheckoutAddressContainer #AddAddressButton:hover {
    opacity: 0.8;
}

.CheckoutInputWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

.CheckoutInputWrapper input {
    flex-basis: calc(80% - 10px);
}

.CheckoutInputWrapper span {
    flex-basis: 20%;
}

.CheckoutAddressChangeButton {
    padding-right: 5px;
    text-align: right;
    font-size: 12px;
    cursor: pointer;
}

.CheckoutAddressChangeButton:hover {
    opacity: 0.8;
}

#AddressInput {
    position: fixed;
    width: 50%;
    max-width: 1200px;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    padding: 100px;
    background: #FFFFFF;
    overflow-y: scroll;
}

#AddressInput input, #AddressInput select {
    border: 1px solid #CCCCCC;
}

.AddressInputRow {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

.AddressInputRow span {
    flex-basis: 30%;
    text-align: left;
    line-height: 40px;
}

.AddressInputRow input, .AddressInputRow select {
    flex-basis: 70%;
}

#AddressCheckbox {
    margin: 50px 0 10px 0;
    padding: 30px 50px;
    background: #F1F1F1;
    border-radius: 5px;
}

#AddressCheckbox label {
    display: block;
    margin-bottom: 10px;
}

.CheckoutAddressBook {
    margin-bottom: 10px;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
}

.CheckoutAddressBook:hover {
    opacity: 0.7;
}

.CheckoutAddressBook p:first-child {
    font-size: 10px;
    line-height: 10px;
    color: #888888
}

#SameAsShippingButton {
    font-size: 13px;
    cursor: pointer;
}

#SameAsShippingButton:hover {
    opacity: 0.8;
}


#OrderInformationBlock {
    width: 800px;
    margin: 0 auto;
}

.OrderInformationTitle {
    margin-bottom: 10px;
    border-bottom: 1px solid #888888;
    font-weight: 500;
}

.OrderInformationHalf {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

.OrderInformationHalf div {
    flex-basis: 50%
}

#OrderSuccessText {
    margin: 200px 0 100px 0;
    text-align: center;
}

#OrderSuccessText h1 {
    margin-bottom: 50px;
}

#OrderSuccessButton {
    margin: 100px 0 200px 0;
    text-align: center;
}

/* Footer */

#FooterContainer {
    clear: both;
    position: relative;
    width: 100%;
    height: 300px;
    background: rgb(30, 30, 30);
    z-index: 99;
}

.FooterMenuL {
    width: 25%;
    float: left;
    padding-top: 30px;
}

.FooterMenuL p, .FooterMenuR p {
    font-weight: bold;
    color: #FFFFFF;
    line-height: 3;
}

.FooterMenuL span, .FooterMenuR span {
    display: block;
    color: #CCCCCC;
    line-height: 2.2;
    cursor: pointer;
}

.FooterMenuL span:hover {
    color: #666666;
}

.FooterMenuR {
    width: 25%;
    float: right;
    padding-top: 30px;
    text-align: left;
}

#FooterShare {
    padding-top: 20px;
}

#FooterShare img {
    height: 30px;
    width: 30px;
    margin-right: 1rem;
    filter: grayscale(100%);    
    cursor: pointer;
}

#FooterShare img:hover {
    filter: grayscale(0%);
}

#FooterCopyBox {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: #000000;
}

#FooterLeft {
    position: relative;
    float: left;
    color: #FFFFFF;

}

#FooterRight {
    position: relative;
    float: right;
    color: #FFFFFF;

}

#FooterLeft span, #FooterRight span {
    height: 30px;
    line-height: 30px;
}

#FooterRight span {
    margin-left: 1rem;
    cursor: pointer;
}

#Newsletter {
    position: relative;
    width: 100%;
    height: 35px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

#Newsletter input {
    position: absolute;;
    background: #FFFFFF;
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 0;
    padding: 0 1rem;
    outline: none;
    resize: none;
}

#Newsletter span {
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 32px;
    color: #333333;
    font-weight: bold;
    cursor: pointer;
}

#Newsletter span:hover {
    opacity: 0.5;
}


/* Media query for SmartPhone */
@media only screen and (max-width: 992px) {

    #FooterContainer {
        height: auto;
    }


    #FooterContainer .RegContainer {
        height: auto !important;
    }

    .FooterMenuL, .FooterMenuR {
        width: 100%;
        float: none;
    }

    #FooterLeft, #FooterRight {
        float: none;
    }
    
    #FooterCopyBox {
        position: relative;
        height: auto;
    }

    #FooterCopyBox .RegContainer {
        height: auto !important;
    }
    
}
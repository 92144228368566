/* MUSTOM, More Than Custom, https://mustom.com */
/* Copyright © Ryu Woosik. All rights reserved. */


#CartAddBox {
    position: relative;
    height: 30px;
    text-align: center;
}

#CartAddBox span {
    position: relative;
    width: 30px;
    height: 30px;
    background: #CCCCCC;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

#CartAddBox span:hover {
    background : #E51F23;
}

#CartAddBox input[type=text] {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 30px;
    border: 1px solid #CCCCCC;
    margin: 0 3px;
    text-align: center;
    vertical-align: top;
}

#CartBottom {
    position: relative;
    text-align: right;
}

#CartPrice {
    position: relative;
    text-align: right;
    padding: 2rem 0.5rem;  
}


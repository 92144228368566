
/* Home */


.WideBanner {
    position: relative;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    text-align: center
}


#BannerTextContainer {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.BannerBigText {
    font-size: 80px;
    line-height: 140%;
    color: #FFFFFF;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.BannerSmallText {
    font-size: 20px;
    line-height: 140%;
    color: #FFFFFF;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
}

#BannerBtn {
    display: inline-block;
    margin-top: 40px;
    padding: 10px 60px;
    background: #D00000;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 0.5s;
}

#BannerBtn:hover {
    background: #FFFFFF;
    color: #000000;
}
/* Category */


#CategoryText {
    position: absolute;
    width: 40%;
    top: 100px;
    right: 50px;
    text-align: left;
}

#CategoryText h1 {
    font-size: 4rem;
    font-weight: bold;
    line-height: 8rem;
    color: #000000;
}

#CategoryText p {
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: #000000;
}

.CategoryBannerButton {
    display: inline-block;
    margin: 100px auto;
    padding: 10px 60px;
    background: #D00000;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

.CategoryTop {
    height: 50px;
    background: #FFFFFF;
    margin-bottom: 20px;
}


/* Media query for SmartPhone */
@media only screen and (max-width: 992px) {

    #CategoryText {
        position: relative;
        padding: 5%;
        width: 100%;
        top: auto;
        right: auto;
    }
    
    #CategoryText h1 {
        font-size: 2rem;
        line-height: 1.6;
    }
    
    #CategoryText p {
        font-size: 1.1rem;
        line-height: 1.6;
    }
    
    .CategoryBannerButton {
        display: block;
        text-align: center;
    }

}


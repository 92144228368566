/* MUSTOM, More Than Custom, https://mustom.com */
/* Copyright © Ryu Woosik. All rights reserved. */


#AccountMenu {
    float: left;
    width: 30%;
}

#AccountMenu ul {
    font-size: 17px;
    line-height: 50px;
    cursor: pointer;
    transition: text-decoration 1s;
}

#AccountMenu ul li:hover {
    text-decoration: underline;
}

.AccountContent {
    float: right;
    width: 70%;
}

.AddressBlock {
    position: relative;
    min-height: 140px;
    margin-bottom: 10px;
    padding: 30px 50px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    text-align: left;
}

.AccountSection {
    margin-bottom: 50px;
}

.AccountSectionMenu {
    display: block;
    border-bottom: 1px solid #888888;
    margin-bottom: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}

.DefaultAddress {
    position: absolute;
    right: 50px;
    top: 30px;
    display: inline-block;
    color: #D00000;
    font-size: 12px;
}

.AddressAction {
    position: absolute;
    bottom: 10px;
    right: 20px;
    text-align: right;
}

.AddressAction span {
    margin-left: 5px;
    color: #E4E4E4;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
}

.AddressAction span:hover {
    color: #D00000;
}

#AddressBottom {
    text-align: right;
    cursor: pointer;
}

#AddressBottom span {
    vertical-align: middle;

}

#AddressBottom span:first-child {
    font-size: 18px;
}

#NameInput {
    position: fixed;
    width: 50%;
    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    padding: 50px 100px;
    background: #FFFFFF;
}

#NameInput input, #NameInput select {
    border: 1px solid #CCCCCC;
}

.NameInputRow {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

.NameInputRow span {
    flex-basis: 30%;
    text-align: left;
    line-height: 40px;
}

.NameInputRow input {
    flex-basis: 70%;
}
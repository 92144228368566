/* MUSTOM, More Than Custom, https://mustom.com */
/* Copyright © Ryu Woosik. All rights reserved. */


#ProductNameBand {
    width: 100%;
    height: 100px;
    margin-top: 50px;
    background: #333333;
}

#ProductNameBand h1 {
    color: #FFFFFF;
    font-size: 40px;
    line-height: 100px;
}

#ProductBread {
    margin-bottom: 20px;
    padding: 14px 0 4px 0;
    border-bottom: 1px solid #E4E4E4;
}

#ProductBread span {
    font-size: 1rem;
    line-height: 30px;
    vertical-align: middle;
    cursor: pointer;
}

#ProductTopMain {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

#ProductLeft {
    flex-basis: 960px;
}

#ProductLeft img {
    float: left;
    display: block;
    margin: 0 auto;
    /* border: 10px solid #FFFFFF; */
}

#ProductLeftAdditional {
    float: left;
    width: 120px;
    margin-right: 20px;
    border: 1px solid #E4E4E4;
    background: #FFFFFF;
}

#AdditionalText {
    background: #333333;
    text-align: center;
}

#AdditionalText span {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 40px;
}

.AdditionalImageWrapper {
    margin: 10px;
    width: 100px;
    height: 100px;
}

.AdditionalImageWrapper img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    cursor: pointer;
}

.AdditionalImageWrapper img:hover {
    opacity: 1;
}

#ProductRight {
    position: relative;
    float: right;
    background: #FFFFFF;
    padding: 50px;
    border: 1px solid #E4E4E4;
    border-radius: 20px;
    flex-basis: 480px;
}

#ProductSku {
    border-bottom: 1px solid #888888;
}

#ProductSku p {
    float: left;
}

#ProductTitle {
    margin: 14px 0 20px 0;
}

#ProductStar {
    text-align: right;
}

#ProductStar span {
    vertical-align: middle;
}

#ShortDescriptionBlock {
    margin: 20px 0 18px 0;
}

.PriceBlock {
    width: 100%;
}

.PriceBlock::after {
    content: "";
    display: table;
    clear: both;
}

.PriceBlock p {
    float: left;
}

.PriceBlock span {
    float: right;
    font-size: 18px;
}

#PriceMax {
    text-decoration: line-through;
}

#PriceMin {
}

#ProductAdd {
    padding: 20px 0;
}

#ProductAdd::after {
    content: "";
    display: table;
    clear: both;
}

#ProductAdd p {
    float: left;
}

#ProductAddBox {
    float: right;
    position: relative;
    height: 30px;
}

#ProductAddBox span {
    position: relative;
    width: 30px;
    height: 30px;
    background: #E4E4E4;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

#ProductAddBox input[type=text] {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 30px;
    margin: 0 3px;
    text-align: center;
    vertical-align: top;
}

#ProductAddButton {
    margin-top: 20px;
}

#ProductAddButton span {
    width: 100%;
}

#AddToContainer {
    display: grid;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

#AddToContainer .RegBtn {
    width: 100%;
}

#AddToContainer .RegBtnLight {
    width: 100%;
}

#ProductTotal {
    width: 100%;
}

#ProductTotal::after {
    content: "";
    display: table;
    clear: both;
}

#ProductTotal p {
    float: left;
}

#ProductTotal span {
    float: right;
    font-size: 18px;
}


/* Option */
#OptionBox {
    padding: 30px;
    margin-top: 20px;
    border: 1px solid #E4E4E4;
    background: #F8F8F8;
    border-radius: 10px;
}


/* Description and Reviews */
.ProductBottomBox {
    margin-bottom: 100px 0;
    padding-bottom: 100px;
}

.ProductBottomTitle {
    margin-top: 100px;
    text-align: center;
    line-height: 40px;
    font-size: 40px;
}

.ProductBottomSubTitle {
    text-align: center;
    font-size: 16px;
}

.ProductBanner {
    position: relative;
    width: 100%;
    height: 400px;
    background-position: center;
}

.ProductBanner h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-shadow: 1px 1px #333333;
    color: #FFFFFF;
    font-size: 60px;
}

.ProductDescription {
    margin-top: 100px;
    padding: 100px 200px;
    background: #FFFFFF;
}

.ProductDescription p {
    margin-bottom: 20px;
    font-size: 16px;
}

#ReviewContainer {
    margin: 50px auto;
    height: auto;

}

#ReviewContainer::after {
    content: "";
    display: table;
    clear: both;
}

#ReviewLeft {
    float: left;
    padding: 50px;
    width: 30%;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    background: #FFFFFF;
}

#ReviewLeft h4 {
    text-align: center;
    margin-bottom: 50px;
}

#ReviewRight {
    float: right;
    padding: 0 50px;
    width: 70%;
}

#ReviewPost input {
    margin-bottom: 10px;
}

#ReviewPost textarea {
    margin-bottom: 40px;
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
}

.ReviewBox {
    margin-bottom: 10px;
    padding: 50px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    overflow: hidden;
}

.ReviewContent {
    word-break: break-all;

}

.ReviewContent span {
    vertical-align: middle;
}

.ReviewContent span:last-child {
    margin-left: 30px;
    vertical-align: middle;
}

.ReviewContent p {
    /* word-break:break-all; */
}

#NoReview {
    width: 100%;
    height: 300px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    text-align: center;
}

#NoReview span {
    line-height: 300px;
}


/* Star Rating */
.RateContainer span:first-child {
    font-size: 20px;
    color: #FFBA0D;
    -webkit-text-stroke: 1px #000000;
}

#SetRateContainer {
    text-align: right;
    margin-bottom: 20px;
}

#SetRateContainer span:last-child {
    margin-left: 10px;
    padding: 4px 10px;
    vertical-align: middle;
    background: rgba(255, 186, 13, 0.5);
    border-radius: 4px;
}

.StarEmpty {
    font-size: 30px;
    color: #FFFFFF;
    -webkit-text-stroke: 1px #000000;
    cursor: pointer;
    vertical-align: middle;
}

.StarFilled {
    font-size: 30px;
    color: #FFBA0D;
    -webkit-text-stroke: 1px #000000;
    cursor: pointer;
    vertical-align: middle;
}


/* Media query for SmartPhone */
@media only screen and (max-width: 992px) {

    #ProductTopMain {
        display: block;
    }

    #ProductLeft {
        width: 100%;
    }

    #ProductLeft img {
        width: 100%;
        height: auto;
        border: none;
    }

    #ProductAddBox {
        float: right;
    }
    
    #ProductAddBox input[type=text] {
        width: calc(100% - 60px);
        margin: 0;
    }


    
}
/* Login */



.SignLeft {
    padding-right: 50px;
}

.SignRight {
    padding-left: 50px;
}
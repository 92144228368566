#HeaderTop {
    position: relative;
    height: 30px;
    background: #000000;
    color: #FFFFFF;
}

#HeaderTop span {
    margin: 0 10px;
    font-size: 0.8rem;
    color: #FFFFFF;
    line-height: 30px;
    cursor: pointer;
}

#HeaderTopLeft {
    float: left;

}

#HeaderTopRight {
    float: right;

}


#HeaderContainer {
    position: relative;
    width: 100%;
    height: 60px;
    z-index: 99;
 }

#HeaderLeft {
    float: left;
    height: 60px;
    margin-left: 2rem;

}


#HeaderLeft span {
    color: #FFFFFF;
    line-height: 60px;
    vertical-align: middle;
}

#HeaderLeft span:hover {
    opacity: 0.8;
}


#HeaderRight {
    float: right;
    margin-right: 2rem;
    line-height: 60px;
    vertical-align: middle;
}

#HeaderRight::after {
    content: "";
    display: table;
    clear: both;
}

#HeaderRight input[type=text] {
    width: 200px;
    height: 2rem;
    background: none;
    outline: none;
    border: none;
    border-radius: 0;
    vertical-align: middle;
    opacity: 0;
    transition: 1.5s;
}

#HeaderRight span {
    cursor: pointer;
    line-height: 60px;
}



.HeaderScroll {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    transform: translateY(-120%);
    transition: transform 1s;
    background: #FFFFFF;
    z-index: 99;
}


.HeaderScroll.isOpen {
    transform: none;
}


#HeaderBar {
    position: absolute;
    width: 100vw;
    bottom: 0;
    height: 4px;
    background: #CCCCCC;
    z-index: 98;
}

#HeaderFill {
    height: 4px;
    width: 0%;
    background: #E51F23;
}

.LogoContainer {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.LogoContainer img{
    width: 30px;
    height: 30px;
    opacity: 1;
    cursor: pointer;
}

.LogoContainer img:hover{
    opacity: 0.7;
}



/* Mobile Menu */
#MobileHeader {
    position: relative;
    background: #FFFFFF;
    width: 100%;
    height: 60px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

#MobileMenu {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    background: #F1F1F1;
    top: 0;
    left: 0;
    z-index: 100;
}

#MobileMenuTop {
    text-align: center;
    background: #FFFFFF;
}

#MobileMenuTop p {
    padding: 20px 0;
    font-size: 20px;
    font-weight: 600;
}

#MobileMenuTop:hover {
    opacity: 0.8;
}


#MobileMenuBody {
    padding: 40px 5% 5% 5%;
}

#MobileMenuBody h4 {
    line-height: 3;
}

#MobileMenuBody h4:hover {
    opacity: 0.8;;
}


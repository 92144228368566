/* MUSTOM, More Than Custom, https://mustom.com */
/* Copyright © Ryu Woosik. All rights reserved. */


/* WebFont : Roboto */

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Thin/Roboto-Thin.woff') format('woff'),
        url('./media/font/Thin/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Light/Roboto-Light.woff') format('woff'),
        url('./media/font/Light/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Regular/Roboto-Regular.woff') format('woff'),
        url('./media/font/Regular/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Regular/Roboto-Regular.woff') format('woff'),
        url('./media/font/Regular/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Medium/Roboto-Medium.woff') format('woff'),
        url('./media/font/Medium/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Bold/Roboto-Bold.woff') format('woff'),
        url('./media/font/Bold/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Bold/Roboto-Bold.woff') format('woff'),
        url('./media/font/Bold/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Black/Roboto-Black.woff') format('woff'),
        url('./media/font/Black/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


/* Global Style */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

body {
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica;
    background: #F7F7F7;
    color: #333333;
    font-size: 14px;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
a:active,
a:visited,
a:link {
    text-decoration: none;
    color: #333333;
    cursor: pointer;
}

ul {
    list-style: none;
}

ol {
    list-style-position: inside;
}

p, span {
    font-size: 1rem;
    line-height: 1.8rem;
}

h1 {
    font-size: 2rem;
    line-height: 2.2rem;
}

h2 {
    font-size: 1.8rem;
    line-height: 2rem;
}

h3 {
    font-size: 1.6rem;
    line-height: 1.8rem;
}

h4 {
    font-size: 1.4rem;
    line-height: 1.6rem;
}

h5 {
    font-size: 1.2rem;
    line-height: 1.4rem;
}


/* Default Input Style */

input[type='text'], input[type='password'], input[type='email'] {
    background: #FFFFFF;
    width: 100%;
    height: 40px;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica;
    padding: 0 1rem;
    outline: none;
    resize: none;
}

input[type='radio'] {
    margin-right: 5px;
    vertical-align: middle;
}

textarea {
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica, Verdana;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    font-size: 1rem;
    padding: 1rem;
    font-weight: normal;
}

select {
    background: #FFFFFF;
    width: 100%;
    height: 40px;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica;
    font-size: 1rem;
    padding: 0 1rem;
    outline: none;
}

input::placeholder {
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica;
    color: #888888;
    font-size: 13px;
}

textarea::placeholder {
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica;
    color: #888888;
    font-size: 13px;
}

.InputButton {
    display: inline-block;
    height: 40px;
    border-radius: 5px;
    background: #D00000;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}


/* Default Table Style */

table {
    border-collapse: collapse;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

th {
    position: relative;
    background: #333333;
    padding: 1rem 0;
    font-weight: normal;
    color: #FFFFFF;
    border: none;
}

tr {
    background: #FFFFFF;
    border: none;
    padding: 1rem 0;
    border-bottom: 1px solid #F1F1F1;
    text-align: center;
}

td {
    border: none;
    border-collapse: collapse;
    padding: 1rem 1rem;
}


/* Inner Table */


.InnerTable {
    border-collapse: collapse;
    box-shadow: none;
    border: 1px solid #E4E4E4;
    background: none;
    color: #333333;
    font-size: 14px;
}

.InnerTable thead th {
    background: none;
    border: none;
    padding: 10px 0 8px 0;
    font-weight: normal;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
    background: none;
}

.InnerTable tbody tr {
    font-weight: normal;
    border: none;
    cursor: default;
}

.InnerTable td {
    padding: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.InnerTable td p, .InnerTable td span {
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


/* Default */
.RegContainer {
    position: relative;
    width: 1440px;
    margin: 0 auto;
}

.RegGrayContainer {
    position: relative;
    width: 1440px;
    margin: 0 auto;
    padding: 100px;
    border: 1px solid #E4E4E4;
    background: #F8F8F8;
}

.RegContainer::after, .RegGrayContainer::after {
    content: "";
    display: table;
    clear: both;
}


.FullContainer {
    position: relative;
    width: 100%;
    margin: 0 auto;
    clear: both;
}

.HalfContainer {
    position: relative;
    width: 50%;
    float: left;
}

.HalfContainer::after {
    content: "";
    display: table;
    clear: both;
}

.HalfLeft {
    padding-right: 10px;
}

.HalfRight {
    padding-left: 10px;
}


/* Table */
.CmsContainer {
    position: relative;
    width: 1024px;
    margin: 0 auto;
}

.WhiteContainer {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 2rem;
}

.WRContainer {
    position: relative;
    background: #FFFFFF;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-top: 7px solid #E51F23;
}

.AlertBox {
    position: absolute;
    width: 400px;
    min-height: 200px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #FFFFFF;
    padding: 3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-top: 7px solid #E51F23;
    text-align: center;
}

.NightWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.NighterWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
}

.tomatoTxt {
    color: #FF4F4F;
    font-size: 1rem;
}

.GuideText {
    font-size: 13px;
    vertical-align: middle;
}

.GuideText::before {
    content: '\24D8';
    margin-right: 5px;
    color: #D00000;
    font-weight: 700;
    font-size: 14px;
}

.ClearFix {
    content: " ";
    clear: both;
}

.closeBtn {
    position: absolute;
    padding: 1rem;
    top: 0;
    right: 0;
    font-size: 2rem;
    color: #333333;
    cursor: pointer;
}

.closeBtn:hover {
    color: #666666
}

.RegBtn {
    display: inline-block;
    padding: 10px 30px;
    background: #D00000;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
}

.RegBtnLight {
    display: inline-block;
    padding: 10px 30px;
    background: #333333;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
}

.RegBtn:hover {
    opacity: 0.8;
}

.RegBtnLight:hover {
    opacity: 0.8;
}

.RegSmallBtn {
    display: inline-block;
    background: #D00000;
    min-width: 100px;
    padding: 0.3rem 1rem;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}

.RegSmallBtnLight {
    display: inline-block;
    background: #333333;
    min-width: 100px;
    padding: 0.3rem 1rem;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}

.RegSmallBtn:hover, .RegSmallBtnLight:hover {
    opacity: 0.8;
}

.FullWidthBtn {
    display: block;
    width: 100%;
    height: 50px;
    background: #D00000;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 1.1rem;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.FixedWidthBtn {
    display: inline-block;
    min-width: 300px;
    background: #D00000;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 1.1rem;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.SmallLinedTextButton {
    display: inline-block;
    color: #D00000;
    border-bottom: 1px solid #D00000;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
}

.SmallTextButton {
    display: inline-block;
    color: #D00000;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
}

.SmallLinedTextButton:hover, .SmallTextButton:hover {
    opacity: 0.5;
}

.PageTitle {
    display: block;
    width: 100%;
    color: #333333;
    text-align: center;
    margin: 120px 0 60px 0;
}


.PageTitle2 {
    display: block;
    width: 100%;
    color: #333333;
    text-align: center;
    margin: 80px 0 10px 0;
}

.SubtitleTxt {
    margin-bottom: 80px;
    text-align: center;
}

.SmallText {
    font-size: 12px;
}


/* Loading Spinner */
/* 
#SpinnerContainer {
    text-align: center;
}

#loadSvg {
    width: 150px;
    animation: loading 3s linear infinite;
}

#loadSvg #loading-inner {
    stroke-dashoffset: 0;
    stroke-dasharray: 300;
    stroke-width: 10;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    animation: loading-circle 2s linear infinite;
    stroke: #D00000;
    fill: transparent;
}

#loadTxt {
    font-weight: bold;
    animation: loading-txt 2s linear infinite;
}

@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes loading-circle {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -600;
    }
}

@keyframes loading-txt {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
 */

.RegNoticeBox {
    position: fixed;
    width: 400px;
    min-height: 200px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
    padding: 3rem 3rem 4rem 3rem;
    text-align: center;
    background: #FFFFFF;
    z-index: 998;
}

.RegInnerNoticeBox {
    position: fixed;
    width: 400px;
    min-height: 200px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateY(0);
    animation: rotateBox 0.7s linear;
    padding: 3rem 3rem 4rem 3rem;
    border: 1px solid #666666;
    text-align: center;
    background: #FFFFFF;
    z-index: 999;
}

@keyframes rotateBox {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) rotateY(0);
    }
    to {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) rotateY(360deg);
    }
}

.RegNoticeBox .message, .RegInnerNoticeBox .message {
    font-size: 1rem;
}

.RegNoticeBottomOneButton {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 6rem);
    text-align: center;
}

.RegNoticeBottomTwoButton {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    bottom: 20px;
    width: calc(100% - 6rem);
    text-align: center;
}

.RegNoticeBottomTwoButton .RegSmallBtn, .RegNoticeBottomTwoButton .RegSmallBtnLight  {
    flex-basis: 49%;
}

.Required::after {
    content: " *";
    color: #FF4F4F;
    font-weight: bold;
}


/* Horizontal Item Box */
.ItemHorizontalContainer {
    display: table;
    width: 100%;
    height: 100px;
    text-align: left;
}

.ItemHorizontalContainer .ItemImage {
    display: table-cell;
    width: 100px;
    height: 100px;
    vertical-align: middle;
    list-style-type: none;
}

.ItemHorizontalContainer .ItemImage img {
    width: 100px;
    height: 100px;
    vertical-align: middle;
}

.ItemHorizontalContainer .ItemName {
    display: table-cell;
    width: calc(60% - 100px);
    height: 100px;
    padding-left: 20px;
}

.ItemHorizontalContainer .ItemPrice {
    display: table-cell;
    width: calc(20%);
    height: 100px;
    vertical-align: middle;
}

.ItemHorizontalContainer .ItemAction {
    display: table-cell;
    width: calc(20%);
    height: 100px;
    vertical-align: middle;
    text-align: right;
}

/* Spinner */
#spinner {
    position: absolute;
    display: none;
    top: 45vh;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

#suspenseSpinner {
    position: absolute;
    display: block;
    top: 45vh;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}
  
#spinner > div, #suspenseSpinner > div {
    width: 18px;
    height: 18px;
    background-color: #D00000;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
  
#spinner #bounce1, #suspenseSpinner #bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

#spinner #bounce2, #suspenseSpinner #bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}


/* Media query for SmartPhone */

@media only screen and (max-width: 992px) {

    .RegContainer {
        width: 100%;
        padding: 5%;
    }
    
    .RegGrayContainer {
        width: 100%;
        padding: 5%;
    }
    
}
/* Item Box */

.ItemBoxContainer {
    position: relative;
    float: left;
    width: 300px;
    height: 420px;
    margin: 10px;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
}

.ItemBoxContainer::after {
    content: "";
    display: table;
    clear: both;
}

.ItemBoxImg {
    width: 280px;
    height: 280px;
    background: #FFFFFF;
    cursor: pointer;
}

.ItemBoxImg img {
    position: relative;
    width: 280px;
    height: 280px;
}

.ItemBoxTxt {
    width: 280px;
    padding: 1rem;
    border-top: 1px solid #F1F1F1;
}

.ItemBoxTxt p:first-child {
    font-size: 1.2rem;
}

.ItemBoxPrice {
    font-size: 1.1rem;
    color: #D00000;
}


.ItemBoxBottom {
    position: absolute;
    width: 260px;
    bottom: 10px;
    text-align: right;
}

.ItemBoxBottom i {
    margin-left: 5px;
    font-size: 1.4rem;
    color: gray;
    text-align: right;
}

.ItemBoxBottom :hover {
    color: #D00000;
}

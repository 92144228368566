/* MUSTOM, More Than Custom, https://mustom.com */
/* Copyright © Ryu Woosik. All rights reserved. */


#BrainTree {
    position: absolute;
    width: 600px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #FFFFFF;
    padding: 3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

#BraintreeLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    animation: textLoading 3s linear infinite;
}

@keyframes textLoading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}